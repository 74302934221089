<template>
  <div id="issue">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />报告详情
        </van-col>
      </van-row>
    </div>
    <div class="issue-detail">
      <div class="detail">
        <van-cell :title="report.report_type_text"  />
        <van-cell title="上报人"  :value="report.user_name+(report.reportable_type=='TouristOffice'?'(旅行社)':'(导游)')"/>
        <van-cell title="带团数">
          <a @click="$router.push(`/dailyWeekly/reportLists?lists_ids=${report.lists_ids}`)"
            class="link">{{report.lists_count}}</a>
        </van-cell>
        <van-cell title="游客人数统计">
          <a @click="$router.push(`/dailyWeekly/reportLists?tourists_ids=${report.tourists_ids}&list_ids=${report.lists_ids}`)"
            class="link">{{report.tourists_count}}</a>
        </van-cell>
        <van-cell title="突发事件统计"  :value="report.emergency_reports_count">
          <a @click="$router.push(`/dailyWeekly/reportLists?reports_ids=${report.emergency_reports_ids}`)"
            class="link">{{report.emergency_reports_count}}</a>
        </van-cell>
        <van-cell title="报告内容" :border="false" />
         <van-row >
            <van-col span="24"  style="padding:10px 16px;font-size:.3rem;color:#969799;">
                {{report.report_content}}
           </van-col>
        </van-row>
        <van-cell title="附件"  :border="false" :value="report.attachment_url?'':'无附件'"/>
        <van-row v-if="report.attachment_url">
            <van-col span="24" style="padding:10px 16px">
           
              <van-uploader :max-count="1" :deletable="false" v-model="fileList" multiple />
            </van-col>
          
        </van-row>
         
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "issue-item",
  data() {
    return {
      report: {},
      active: 1,
      fileList:[]
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    // onItemClick() {
    //   this.$router.push("/trip/item");
    // },
    async get_report(id) {
      const res = await this.$apis.get_report(id);
      this.report = res.data;
      if(res.data.attachment_url){
          this.fileList = [{url:res.data.attachment_url}]
      }
    },
    
  },
  components: {},
  created() {
    const id = this.$route.query.id ? this.$route.query.id : "1";
    this.get_report(id)
  },
};
</script>

<style scoped>
#issue {
  height: 100vh;
  /* background: #f3f3f3; */
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.issue-detail {
  padding: 0.2rem .2rem 2rem .2rem;
  height: 90vh;
  overflow-y: scroll;
  overflow: hidden;
  padding-bottom: 4rem;
}
.detail {
  /* min-height: 80vh; */
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.4rem;
}
.detail-title {
  text-align: center;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.content {
  font-size: 14px;
  color: #666666;
  text-align: justify;
  margin-top: 0.5rem;
}
.date {
  font-size: 14px;
  color: #666666;
  text-align: right;
  margin-top: 0.5rem;
}
.link {
  color: #1989fa;
}
</style>
